<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="goBack()">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>#{{categorySlug}}</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <div class="app-max-width">
        <div v-if="pages.length > 0">
          <ion-list>
            <ion-item v-for="page in pages" :key="page" button detail :href="'/page/'+page.slug">
              <div tabindex="0"></div>
              <ion-avatar v-if="page.image != null && page.image != ''" slot="start">
                <img :src="page.image" >
              </ion-avatar>
              {{page.name}}
            </ion-item>
          </ion-list>
        </div>
      </div>
    </ion-content>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonList, IonItem, IonAvatar } from '@ionic/vue';
import { chevronBack, add, reorderThree, checkmark } from 'ionicons/icons';
import { defineComponent, ref, onMounted, computed } from 'vue';
import doAuth from '../../services/auth';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../../services/api';

export default defineComponent({
  name: 'Category',
  components: {
    IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonList, IonItem, IonAvatar
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const categorySlug = ref(null);
    const pages = ref([]);

    onMounted(() => {
      categorySlug.value = router.currentRoute._rawValue.params.slug
      getPagesByCategory()
    })

    function getPagesByCategory() {
      //presentLoading()
      apiClient.get('/api/pages-by-category/slug/'+categorySlug.value, {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        pages.value = response.data
      }).catch(error => {
        console.log(error)  
      });          
    }

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    return {
      chevronBack, add, reorderThree, checkmark, router, updateRoute, goBack, authUser, categorySlug, pages
    }
  },
  watch: {
    pageId: function() {
      if(this.pageId > 0) {
        this.getPage()
      }
    },
  }
});
</script>

<style scoped>

</style>